export const NotificationMixin = {
  methods: {
    openNotificationWithIcon(type, title, text) {
      this.$notification[type]({
        message: title,
        description: text,
      });
    },
  },
};
